import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

import { PostContentParser } from '../../utils/parser';
import { PageTitle, PageContent } from '../components/styles/layout';

const PageTemplate = props => {
	const title =
		(props.data.wordpressPage.yoast_json_ld.wordpress_graph &&
			props.data.wordpressPage.yoast_json_ld.wordpress_graph[2] &&
			props.data.wordpressPage.yoast_json_ld.wordpress_graph[2].name) ||
		props.data.wordpressPage.title;
	const description =
		(props.data.wordpressPage.yoast_json_ld.wordpress_graph &&
			props.data.wordpressPage.yoast_json_ld.wordpress_graph[2] &&
			props.data.wordpressPage.yoast_json_ld.wordpress_graph[2].description) ||
		props.data.wordpressPage.title;
	return (
		<Layout location={props.location}>
			<SEO title={title} description={description} />

			<PageTitle dangerouslySetInnerHTML={{ __html: props.data.wordpressPage.title }} isHidden={true} />
			<PageContent>{PostContentParser(props.data.wordpressPage.content, props.data.allWordpressWpMedia)}</PageContent>
		</Layout>
	);
};
export default PageTemplate;

export const pageQuery = graphql`
	query($id: String!) {
		wordpressPage(id: { eq: $id }) {
			title
			content
			date(formatString: "MMMM DD, YYYY")
			yoast_json_ld {
				wordpress__graph {
					name
					datePublished
					dateModified
					width
					height
					caption
					description
				}
			}
		}
		site {
			id
			siteMetadata {
				title
				description
			}
		}
		allWordpressWpMedia {
			edges {
				node {
					source_url
					alt_text
					localFile {
						publicURL
						childImageSharp {
							fluid(maxWidth: 600) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
			}
		}
	}
`;
